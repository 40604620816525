import React, { useEffect, useState } from 'react';
import './TimeLine2.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const TimeLine2 = ({ timeLine, color }) => {
    useEffect(() => {
        AOS.init({ duration: 1500 });
    })
 
   

    return (
        <div class="timeline" >
            {/* <div class='showLine' style={color ? { backgroundImage: 'linear-gradient(transparent 60%, #EA5136 50%)' } : { backgroundImage: 'linear-gradient(transparent 60%, #f5f2f2 40%)' }}></div> */}

            <div class='tline row justify-content-center '>
                <div class='col-4 h-100'  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 462 1460" fill="none">
                        <path d="M0.989075 2L162.288 46.8675C278.741 79.9825 284.834 242.75 171.182 284.478V284.478C58.7455 325.76 63.1139 486.26 177.63 521.365L289.861 555.77C424.192 596.95 448.431 776.694 329.806 851.985L122.749 983.404C79.4848 1010.86 53.2732 1058.55 53.2732 1109.79V1109.79C53.2732 1160.17 78.6154 1207.17 120.707 1234.85L461.5 1459" stroke={color ? '#ffffff' : '#1d2b77'} stroke-width="3" />
                    </svg>
                </div>
            </div>


            <div class='timeLineNews' style={color ? { color: 'white' } : { color: "#1d2b77" }}>
                <div data-aos="zoom-in" class='tPoint row justify-content-start' style={{ marginTop: '-50px' }}>
                    <div class='col-lg-4 col-md-5'>
                        <div class=' text-start my-2' style={{ width: '100%' }}>
                            <p class='fs-2 fw-bold'>{timeLine?.one?.year}</p> 
                            <p class='fw-bold'>{timeLine?.one?.title}</p>
                            <i class=''>{timeLine?.one?.title2}</i> 

                            <p class=''>{timeLine?.one?.text}</p>
                        </div>
                        <div class='col-12 tlImg'>
                            <img src={timeLine?.one?.img} style={{ width: '100%', borderRadius: '15px' }} alt="" />
                        </div>
                    </div>
                </div>

                <div data-aos="zoom-in" class='tPoint row justify-content-end'>
                    <div class='col-lg-4 col-md-5'>
                        <div class='col-12 tlImg'>
                            <img src={timeLine?.two?.img} style={{ width: '100%', borderRadius: '15px' }} alt="" />
                        </div>
                        <div class='my-2  text-start  ' style={{ width: '100%' }}>
                            <p class='fs-2 fw-bold'>{timeLine?.two?.year}</p>
                            <p class='fw-bold'>{timeLine?.two?.title}</p>
                            <i class=''>{timeLine?.two?.title2}</i>

                            <p class=''>{timeLine?.two?.text}</p>
                        </div>
                    </div>
                </div>

                <div data-aos="zoom-in" class='tPoint row justify-content-start'>
                    <div class='col-lg-4 col-md-5'>
                        <div class='col-12 tlImg'>
                            <img src={timeLine?.three?.img} style={{ width: '100%', borderRadius: '15px' }} alt="" />
                        </div>
                        <div class='  my-2 text-start ' style={{ width: '100%' }}>
                            <p class='fs-2 fw-bold'>{timeLine?.three?.year}</p>
                            <p class='fw-bold'>{timeLine?.three?.title}</p>
                            <i class=' '>{timeLine?.three?.title2}</i>


                            <p class=''>{timeLine?.three?.text}</p>
                        </div>
                    </div>
                </div>

                <div data-aos="zoom-in" class='tPoint row justify-content-end'>
                    <div class='col-lg-4 col-md-5'>
                        <div class='col-12 tlImg'>
                            <img src={timeLine?.four?.img} style={{ width: '100%',borderRadius: '15px' }} alt="" />
                        </div>
                        <div class=' my-2   text-start  ' style={{ width: '100%' }}>
                            <p class='fs-2 fw-bold'>{timeLine?.four?.year}</p> 
                            <p class='fw-bold'>{timeLine?.four?.title}</p>
                            <i class=''>{timeLine?.four?.title2}</i>


                            <p class=''>{timeLine?.four?.text}</p>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default TimeLine2;
