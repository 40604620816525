import React, { useEffect, useState } from 'react'
import './Achievements2.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useLanguage } from '../LanguageContext';
import { Helmet } from 'react-helmet';

const englishData = {
    section1: {
        title: 'Awards'
    },
    section2: {
        year1: {
            year: "2024",
            title: 'Visionary leaders award - Chairman',
            text2: 'Shri Tejaskumar Bipinchandra Patel',
            text: 'The Kaira Dist. Central Co-op Bank LTD',
            img: `${require('../../images/Award 1.png')}`
        },
        year2: {
            year: "2024",
            title: "Digital Banking Innovation Awards",
            text2: "Best Bank of The Year",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 2.png')}`
        },
        year3: {
            year: "2024",
            title: "Best Banking in Credit Growth",
            text2: "Iconic Leader Awards 2024",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 3.png')}`
        },
        year4: {
            year: "2024",
            title: "Best NPA Management",
            text2: "Large DCCB Category",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 4.png')}`
        },
        year5: {
            year: "2024",
            title: "VTV News",
            text2: "Untold Story",
            text: "the kaira dist. central co-op bank ltd",
            img: `${require('../../images/Award 5.png')}`
        },
        year6: {
            year: "2024",
            title: "Paloalto | Altisec ",
            text2: "Presenting Partner",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 6.png')}`
        },
        year7: {
            year: "2024",
            title: "Best IT Head of The Year",
            text2: "Alpeshbhai Contractor",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 7.png')}`
        },
        year8: {
            year: "2024",
            title: "Jury Award",
            text2: "DCC Bank Categoy Deposits Between 1000 to 2000 Cr.",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 8.png')}`
        },
        year9: {
            year: "2023",
            title: "First Rank",
            text2: "DCC Bank Categoy Deposits Between 2000 to 2500 Cr.",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 9.png')}`
        },
        year10: {
            year: "2016",
            title: "Third Rank",
            text2: "Deposit - 1101 to 1350 Cr.",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 10.png')}`
        },
        year11: {
            year: "2016",
            title: "Technology Puraskar",
            text2: "Galaxy lnma | Avies Publication",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 11.png')}`
        },
        year12: {
            year: "2016",
            title: "Gujarat The Growth Engine of India",
            text2: "Mr. Tejash Patel",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 12.png')}`
        },
     
    }
}

const gujratData = {
    section1: {
        title: 'પુરસ્કારો'
    },
    section2: {
        year1: {
            year: "2024",
            title: 'Visionary leaders award - Chairman',
            text2: 'Shri Tejaskumar Bipinchandra Patel',
            text: 'The Kaira Dist. Central Co-op Bank LTD',
            img: `${require('../../images/Award 1.png')}`
        },
        year2: {
            year: "2024",
            title: "Digital Banking Innovation Awards",
            text2: "Best Bank of The Year",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 2.png')}`
        },
        year3: {
            year: "2024",
            title: "Best Banking in Credit Growth",
            text2: "Iconic Leader Awards 2024",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 3.png')}`
        },
        year4: {
            year: "2024",
            title: "Best NPA Management",
            text2: "Large DCCB Category",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 4.png')}`
        },
        year5: {
            year: "2024",
            title: "VTV News",
            text2: "Untold Story",
            text: "the kaira dist. central co-op bank ltd",
            img: `${require('../../images/Award 5.png')}`
        },
        year6: {
            year: "2024",
            title: "Paloalto | Altisec ",
            text2: "Presenting Partner",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 6.png')}`
        },
        year7: {
            year: "2024",
            title: "Best IT Head of The Year",
            text2: "Alpeshbhai Contractor",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 7.png')}`
        },
        year8: {
            year: "2024",
            title: "Jury Award",
            text2: "DCC Bank Categoy Deposits Between 1000 to 2000 Cr.",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 8.png')}`
        },
        year9: {
            year: "2023",
            title: "First Rank",
            text2: "DCC Bank Categoy Deposits Between 2000 to 2500 Cr.",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 9.png')}`
        },
        year10: {
            year: "2016",
            title: "Third Rank",
            text2: "Deposit - 1101 to 1350 Cr.",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 10.png')}`
        },
        year11: {
            year: "2016",
            title: "Technology Puraskar",
            text2: "Galaxy lnma | Avies Publication",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 11.png')}`
        },
        year12: {
            year: "2016",
            title: "Gujarat The Growth Engine of India",
            text2: "Mr. Tejash Patel",
            text: "The Kaira Dist. Central Co-op Bank LTD",
            img: `${require('../../images/Award 12.png')}`
        },
     
    }
}

const Awards = () => {

    const { language } = useLanguage();
    const [achivmentsData, setAchivmentsData] = useState()

    useEffect(() => {
        if (language) {
            setAchivmentsData(englishData)
        } else {
            setAchivmentsData(gujratData)
        }
    }, [language])

    useEffect(() => {
        let path = document.querySelector('path');
        let pathLength = path.getTotalLength();
        let lineContainer = document.querySelector('.line-container');

        path.style.strokeDasharray = pathLength + " " + pathLength;
        path.style.strokeDashoffset = pathLength;

        window.addEventListener('scroll', () => {
            let scrollPercentage = (document.documentElement.scrollTop + document.body.scrollTop) * 1.1 / (document.documentElement.scrollHeight - document.documentElement.clientHeight);
            let drawLength = pathLength * scrollPercentage;
            path.style.strokeDashoffset = Math.max(pathLength - drawLength)
            path.style.transition = "0s"

        });
    }, []);
    return (
        <div style={{ overflow: 'hidden' }}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Tejash Patel | Official</title>
                <meta name="description" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
                <meta name="keywords" content="Tejash Patel, Chairman of KDCC Bank, Gujarat Tobacco Fedration, Petlad Sojitra Taluka Sangh, Solar Co-operative, Farmer’s FPO’s etc and Director of Gujcomasol,  Gujarat Regulated Markets Federation, APMC, Petlad etc. and RGB Delegate of Krubhco and IFFCO, New Delhi." />
            </Helmet>
            <div style={{ height: '50vh' }}></div>
            <div class='blueBg ' style={{ position: 'absolute', height: '100vh', top: '0', left: '0', width: '100%', zIndex: '0', alignItems: 'start' }}>
                <div>
                    <div style={{ height: '20vh' }}></div>
                    <p class='experienceText fw-bold'>{achivmentsData?.section1?.title} </p>
                </div>
            </div>

            <div class='position-relative achivPc ' >
                <div style={{ height: '150px' }}></div>
                <div class=' row justify-content-center ' style={{ width: '100%', height: '100%' }}>
                    <div class=''>

                        <svg width="100%" height="620vh" viewBox="0 0 338 3539" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.5 1L194.5 98L231.956 122.013C347.5 196.091 345.956 365.451 229.08 437.409L211.379 448.307C96.5961 518.976 101.991 687.599 221.057 750.786V750.786C338.191 812.947 345.806 977.932 234.898 1050.62L179.559 1086.89C65.1882 1161.85 76.1364 1332.86 199.131 1392.63V1392.63C327.511 1455 332.273 1636.17 207.347 1705.21L192.949 1713.17C75.3927 1778.13 76.6537 1947.55 195.164 2010.75L210.903 2019.15C318.576 2076.57 324.067 2228.88 220.808 2293.91L189.442 2313.67C92.3137 2374.84 105.865 2520.43 212.614 2562.63V2562.63C321.77 2605.77 332.724 2755.95 230.984 2814.48L199.42 2832.63C86.5794 2897.55 96.521 3063.5 216.305 3114.47L234.588 3122.26C366.371 3178.34 371.666 3363.18 243.31 3426.71L19.5 3537.5" stroke="#1d2b77" stroke-width="2" />
                        </svg>


                    </div>

                </div>

                <div class='position-absolute m-auto ' style={{ top: '0', left: '0', width: '100%' }}>
                    <div class='col-11 m-auto'>
                        <div class='row justify-content-start align-items-center mt-5  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year1?.year}</p>
                                        <p class='fw-bold fs-5 ' >{achivmentsData?.section2?.year1?.title}</p>
                                        <i>{achivmentsData?.section2?.year1?.text2}</i>
                                        <p class=' ' >{achivmentsData?.section2?.year1?.text}</p>
                                    </div>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year1?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year2?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year2?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year2?.title}</p>
                                        <i>{achivmentsData?.section2?.year2?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year2?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year2?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year3?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year3?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year3?.title}</p>
                                        <i>{achivmentsData?.section2?.year3?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year3?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year3?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year4?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year4?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year4?.title}</p>
                                        <i>{achivmentsData?.section2?.year4?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year4?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year4?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year5?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year5?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year5?.title}</p>
                                        <i>{achivmentsData?.section2?.year5?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year5?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year5?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year6?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year6?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year6?.title}</p>
                                        <i>{achivmentsData?.section2?.year6?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year6?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year6?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year7?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year7?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year7?.title}</p>
                                        <i>{achivmentsData?.section2?.year7?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year7?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year7?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year8?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year8?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year8?.title}</p>
                                        <i>{achivmentsData?.section2?.year8?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year8?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year8?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year9?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year9?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year9?.title}</p>
                                        <i>{achivmentsData?.section2?.year9?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year9?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year9?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year10?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year10?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year10?.title}</p>
                                        <i>{achivmentsData?.section2?.year10?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year10?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year10?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year11?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year11?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year11?.title}</p>
                                        <i>{achivmentsData?.section2?.year11?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year11?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year11?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class='row justify-content-end align-items-center m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5   text-end '>
                                <div class='row justify-content-end'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year12?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-end my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year12?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year12?.title}</p>
                                        <i>{achivmentsData?.section2?.year12?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year12?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year12?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class='row justify-content-start align-items-center  m-0' style={{ height: '50vh' }} data-aos="zoom-in">
                            <div class='col-md-5 text-start'>
                                <div class='row justify-content-start'>
                                    <div class='col-10 p-0 '>
                                        <img class='rounded-4' src={achivmentsData?.section2?.year13?.img} style={{ height: '220px' }} alt="" />
                                    </div>
                                    <div class='col-10  text-start my-2'>
                                        <p class='fw-bold fs-2'>{achivmentsData?.section2?.year13?.year}</p>
                                        <p class='fw-bold fs-5' >{achivmentsData?.section2?.year13?.title}</p>
                                        <i>{achivmentsData?.section2?.year13?.text2}</i>
                                        <p class='' >{achivmentsData?.section2?.year13?.text}</p>
                                        {/* <p class='  fw-bold' style={{ fontSize: '14px' }}  >{achivmentsData?.section2?.year13?.address}</p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class='position-relative achivMb ' >
                <div class='col-11 m-auto'>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year1?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year1?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year1?.title}</p>
                            <i>{achivmentsData?.section2?.year1?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year1?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year1?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year2?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year2?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year2?.title}</p>
                            <i>{achivmentsData?.section2?.year2?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year2?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year2?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year3?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year3?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year3?.title}</p>
                            <i>{achivmentsData?.section2?.year3?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year3?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year3?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year4?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year4?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year4?.title}</p>
                            <i>{achivmentsData?.section2?.year4?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year4?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year4?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year5?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year5?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year5?.title}</p>
                            <i>{achivmentsData?.section2?.year5?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year5?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year5?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year6?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year6?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year6?.title}</p>
                            <i>{achivmentsData?.section2?.year6?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year6?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year6?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year7?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year7?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year7?.title}</p>
                            <i>{achivmentsData?.section2?.year7?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year7?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year7?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year8?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year8?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year8?.title}</p>
                            <i>{achivmentsData?.section2?.year8?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year8?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year8?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year9?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year9?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year9?.title}</p>
                            <i>{achivmentsData?.section2?.year9?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year9?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year9?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year10?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year10?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year10?.title}</p>
                            <i>{achivmentsData?.section2?.year10?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year10?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year10?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year11?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year11?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year11?.title}</p>
                            <i>{achivmentsData?.section2?.year11?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year11?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year11?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-end mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year12?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-left">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year12?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year12?.title}</p>
                            <i>{achivmentsData?.section2?.year12?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year12?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year12?.address}</p> */}
                        </div>
                    </div>

                    <div class=' text-start mt-5'>
                        <div class='' data-aos="zoom-in">
                            <img class='rounded-4' src={achivmentsData?.section2?.year13?.img} style={{ width: '100%' }} alt="" />
                        </div>
                        <div class='col-11 ' data-aos="fade-right">
                            <p class='fs-1 fw-bold'>{achivmentsData?.section2?.year13?.year}</p>
                            <p class='fw-semibold fs-2' >{achivmentsData?.section2?.year13?.title}</p>
                            <i>{achivmentsData?.section2?.year13?.text2}</i>
                            <p class='' >{achivmentsData?.section2?.year13?.text}</p>
                            {/* <p class='fw-bold ' style={{ fontSize: '14px' }} >{achivmentsData?.section2?.year13?.address}</p> */}
                        </div>
                    </div>


                </div>
            </div>
            <div style={{ height: '150px' }}></div>

        </div>
    )
}

export default Awards